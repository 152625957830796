import React from 'react'
// import Button from '../../../../Button/Button'
import style from './Sclad.module.css'
// import Title from "../../Title";
import { useEffect, useState, useContext } from "react";
import { QtdContext } from "../../../../../context/qtdContext";
import $api from "../../../../../http/index.ts";
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
import { FaEdit, FaRemoveFormat } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import ModalEdit from '../Modal/Modal';
import { confirmAlert } from 'react-confirm-alert'; // Import
import '../NewOrders/react-confirm-alert.css'; // Import css
import { FcInfo } from "react-icons/fc";
import { BACKURL } from '../../../../../conf.js'
import ModalEditOptUser from '../Modal/ModalOptUser';
import styled from "styled-components";
import Loading from 'react-fullscreen-loading';

const Shops = () => {
    const { admins, setAdmins, shops, setShops, users, admin, servOrders, setSclad, modalEdit, setModalEdit, modalEditOptUser, setModalEditOptUser } = useContext(QtdContext);
    const [loading, setLoading] = useState(false);

    const Shops = shops.sort((a, b) => (b.name) - (a.name))
    const TCheckBox = styled.input.attrs({
        type: "checkbox"
    })`
        display: none;
      `;

    const TCheckBoxWrapper = styled.div`
        background: ${p => (p.checked ? "#3f81ff" : "#ececec")};
        border-radius: 4rem;
        height: 32px;
        width: 68px;
        position: relative;
        transition: cubic-bezier(0.56, 0, 0.38, 1) 0.3s;
        cursor: pointer;
      `;

    const TCheckBoxToggle = styled.div`
        background: #fff;
        border-radius: 4rem;
        height: 26px;
        width: 26px;
        top: 3px;
        left: ${p => (p.checked ? "38px" : "4px")};
        position: absolute;
        transition: cubic-bezier(0.56, 0, 0.38, 1) 0.3s;
      `;

    const ToggleCheckbox = props => {
        return (
            <TCheckBoxWrapper checked={props.checked} onClick={props.onChange}>
                <TCheckBoxToggle checked={props.checked} />
                <TCheckBox {...props} />
            </TCheckBoxWrapper>
        );
    };
    const toggle = async (id) => {
        try {
            setLoading(true)
            // console.log(id)
            const deleteProd = await $api.get(`${BACKURL}/api/utbteam/shops/showed/${id}`)
            setShops(shops.map(pr => {
                if (pr.id == id) {
                    return deleteProd.data
                } else {
                    return pr
                }
            }))
            //   setSclad(sclad.map(item => item.id == id ? deleteProd.data : item))
            setLoading(false)
        } catch (e) {
            setLoading(false)

            console.log(e)
        }
    };
    const deleteProd = async (id) => {
        const DeletedId = await $api.get(`${BACKURL}/api/utbteam/shops/delete/${id}`)
        const newprod = shops.filter((post) => post.id != id)
        setShops(newprod)
        alert('Точка удалена! Эта функция будет ограничена!')
    }
    const submit = async (func, data) => {
        confirmAlert({
            title: 'Подтвердите, чтобы продолжить!',
            message: 'Вы уверены, в том, что хотите удалить магазин?',
            buttons: [
                {
                    label: 'Да!',
                    onClick: async () => await func(data)
                },
                {
                    label: 'Нет!',
                    //   onClick: () => alert('Click No')
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
        });
    };
    return (<>
        <div style={{ overflowX: 'auto' }}>

            <br />
            <table className={style.table}>
                <thead className={style.tableth}>

                    <tr>
                        <td className={style.tabletd}>№</td>
                        <td className={style.tabletd}>id</td>
                        <td className={style.tabletd}>Имя</td>
                        <td className={style.tabletd}>Склад 1С</td>
                        <td className={style.tabletd}>Адрес</td>
                        <td className={style.tabletd}>Телефон</td>
                        <td className={style.tabletd}>Доступен</td>

                        <td className={style.tabletd}>Действия</td>

                    </tr>
                </thead>
                <tbody>
                    {Shops.map(
                        (shop, index) => {


                            return <>
                                <tr>
                                    <td className={style.tabletd}>{index + 1}</td>
                                    <td className={style.tabletd}>{shop.id}</td>

                                    <td className={style.tabletd}>{shop.name}</td>
                                    <td className={style.tabletd}>{shop.name1c}</td>
                                    <td className={style.tabletd}>{shop.adress}</td>
                                    <td className={style.tabletd}>{shop.tel}</td>
                                    <td className={style.tabletd}>
                                        <div className={style.cell}>
                                            <ToggleCheckbox checked={shop.showed} onChange={() => toggle(shop.id)} />

                                        </div>  </td>
                                    <td className={style.tabletd}>
                                        {/* <div className={style.cell}> */}
                                            <AiFillDelete onClick={() => { submit(deleteProd, shop.id) }} className={style.icon} />
                                            <FaEdit className={style.icon}
                                            //  onClick={() =>
                                            //     edit(prod.id)} 
                                                />

                                        {/* </div> */}
                                    </td>

                                </tr>

                            </>
                        }
                    )}
                </tbody>
            </table>
            {/* {modalEditOptUser && (
                <ModalEditOptUser
                    thisChange={thisChangeOpt}
                    setThisChange={setThisChangeOpt}
                // prod = {prod}
                />

            )} */}
        </div>
        {loading && (
                <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
            )}
    </>
    )
}
export default Shops