import React from 'react'
// import Button from '../../../../Button/Button'
import style from './NewProdForm.module.css'
// import Title from "../../Title";
import Button from '../../../../Button/Button'
import $api from "../../../../../http/index.ts";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { useEffect, useState, useContext } from "react";
import { QtdContext } from "../../../../../context/qtdContext";
// import $api from "../../../../../http/index.ts";
// // import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// // import 'react-tabs/style/react-tabs.css';
import axios from "axios";
// import ModalEdit from '../Components/Modal/Modal';
import { BACKURL } from '../../../../../conf.js'
import Loading from 'react-fullscreen-loading';
import { FileUploader } from "react-drag-drop-files";
const fileTypes = ["JPG", "PNG", "GIF"];
const NewProdForm = () => {
    const { sclad, setSclad, modalEdit, setModalEdit, setServerProd } = useContext(QtdContext);
    // const { modal, setModal } = useState(false);
    const [loading, setLoading] = useState(false)
    const [imageFile, setImageFile] = useState(null);
    // useEffect(() => {
    //     window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    // }, [])
    const [file, setFile] = useState(null);
    const [fileURL, setFileURL] = useState(null);
    const handleChange = (file) => {
        if (file.type === 'image/heic' || file.type === 'image/heif') {
            alert('HEIC/HEIF images are not supported. Please convert your image to JPEG or PNG.');
        }
        setFile(file);
        setFileURL(URL.createObjectURL(file))
        // console.log(file)
    };
    const [newProd, setNewProd] = useState({
        type: '',
        name: "",
        kategory: '',
        podkategory: '',
        scladqty: '',
        price: '',
        shcode: '',
        forwho: '',
        about: '',
        forwho: '',
        photo: '',
        mnt: '',
        brand: '',
        recomendation: ''
    })
    const [err, setErr] = useState({})
    const create = async (e) => {
        try {
            e.preventDefault();

            let t = {
                type: false,
                name: false,
                kategory: false,
                podkategory: false,
                scladqty: false,
                forwho: false,
                price: false,
                shcode: false,
                about: false,
                photo: false,
                recomendation: false,
                mnt: false,
                brand: false
            }

            if (!newProd.type) {
                t.type = 'type обязательно к заполнению!'
            }
            if (!newProd.name) {
                t.name = 'Наименование обязательно к заполнению!'
            }
            if (!newProd.kategory) {
                t.kategory = 'Категория обязательна к выбору!'
            }
            if (newProd.kategory == 'Каталог товаров') {
                if (!newProd.podkategory) {
                    t.podkategory = 'Подкатегория обязательна к выбору!'
                }

            }

            if (!newProd.brand) {
                t.brand = 'brand обязательн к заполнению!'
            }
            if (!newProd.mnt) {
                t.mnt = 'Mnt обязательн к заполнению!'
            }
            if (!newProd.price) {
                t.price = 'Розничная цена обязательна к заполнению!'
            }
            if (!newProd.forwho) {
                t.forwho = 'Описание обязательно к заполнению!'
            }
            if (!newProd.shcode) {
                t.shcode = 'ШК обязательно к заполнению!'
            }
            // if (!file) {
            //     t.photo = 'photo обязательно к заполнению!'
            // }
            // if (!newProd.recomendation) {
            //     t.recomendation = 'recomendation обязательно к заполнению!'
            // }
            console.log(t)
            setErr(t)
            if (
                newProd.name &&
                newProd.kategory &&
                newProd.mnt &&
                newProd.price &&
                newProd.brand &&
                newProd.forwho &&
                newProd.shcode &&
                newProd.type &&
                // newProd.recomendation &&
                !t.mnt &&
                !t.brand &&
                !t.name &&
                !t.kategory &&
                !t.type &&
                !t.price &&
                !t.about &&
                !t.shcode &&
                !t.photo &&
                !t.forwho
                // !t.recomendation
            ) {
                if (!file) {
                    alert('Пожалуйста, выберите файл для загрузки.');
                    return;
                }
                const formData = new FormData();
                // const [file, setFile] = useState(null);
                formData.append('image', file);
                formData.append('name', newProd.name); // дополнительное поле
                formData.append('forwho', newProd.forwho); // дополнительное поле
                formData.append('podkategory', newProd.podkategory);
                formData.append('kategory', newProd.kategory);
                formData.append('price', newProd.price); // дополнительное поле
                formData.append('about', newProd.about);
                formData.append('shcode', newProd.shcode); // дополнительное поле
                formData.append('type', newProd.type);
                formData.append('recomendation', newProd.recomendation);
                formData.append('brand', newProd.brand);
                formData.append('mnt', newProd.mnt);
                setLoading(true)



                console.log('formData', formData, file, fileURL)
                // const response = await fetch(`${BACKURL}/uploadpick`, {
                //     method: 'POST',
                //     body: formData,
                // });
                const response = await $api.post(`${BACKURL}/uploadpick`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                // setLoading(false  )
                if (response) {
                    // const { url } = response.data;
                    console.log(response.data)
                    setSclad([...sclad,response.data])
                    // setImageUrl(url);
                } else {
                    console.error('Failed to upload image');
                }

                setNewProd({
                    name: "",
                    kategory: '',
                    podkategory: '',
                    scladqty: '',
                    price: '',
                    shcode: '',
                    forwho: '',
                    about: '',
                    forwho: '',
                    photo: '',
                    mnt: '',
                    brand: '',
                    recomendation: ''
                })
                setFile(null)
                setFileURL(null)
                setLoading(false)

                alert('Готово!')
            }

            // console.log(newProd)
        } catch (e) {
            setLoading(false)
            alert('Ошибка!')
            // console.log(e)
        }

    }

    // Обработчик выбора файла
    const handleFileChange = (event) => {
        setImageFile(event.target.files[0]); // Сохраняем выбранный файл
    };
    // useEffect(() => {
    //     if (newProd.type == 'Наша продукция') {
    //         setNewProd({ ...newProd, brand: 'KOTOPES' })
    //     }

    // }, [newProd])
    return (<>
        <br />
        <div className={style.container_form}>

            <div className={style.cards_produtos}
            // style={{ opacity: scladqty == 0 ? "0.5" : "1" }}
            >
                {/* <img id={id} className={style.img} src={tt} /> */}
                <div className={style.imgcont} style={{ height: '250px' }}>
                    <LazyLoadImage
                        // id={id}
                        className={style.img}
                        src={fileURL}
                        alt="Example Image"
                        height="100%"
                        effect="blur"

                    />
                </div>
                <FileUploader handleChange={handleChange} name="file"
                //  types={fileTypes}
                />
                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                    <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                        <label style={{ color: err.name ? 'red' : 'black' }} for="name">Введите Наименование:</label>

                        <input value={newProd.name} type="text" id="name" name="name" onChange={(event) => {
                            setNewProd({ ...newProd, name: event.target.value });
                        }} />
                    </div>

                    <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                        <label style={{ color: err.type ? 'red' : 'black' }} for="pet-select">Выберите Категорию:</label>

                        <select value={newProd.type} name="pets" id="pet-select" onChange={(event) => {
                            if (event.target.value == "Наша продукция") {
                                setNewProd({ ...newProd, type: event.target.value, brand: "KOTOPES" });

                            } else {
                                setNewProd({ ...newProd, type: event.target.value });
                            }
                        }}>
                            <option value="">--Выберите тип товара--</option>
                            <option value="Наша продукция">Наша продукция</option>
                            <option value="Каталог товаров">Каталог товаров</option>
                        </select>

                        {/* {err.kategory && (
                            <p style={{ color: "red" }}>{err.kategory}</p>
                        )} */}
                    </div>
                    <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                        <label style={{ color: err.forwho ? 'red' : 'black' }} for="pet-select">Для кого:</label>

                        <select value={newProd.forwho} name="pets" id="pet-select" onChange={(event) => {
                            setNewProd({ ...newProd, forwho: event.target.value });
                        }}>
                            <option value="">--Выберите для кого продукция--</option>

                            <option value="Для кошек">Для кошек</option>
                            <option value="Для собак">Для собак</option>
                            <option value="Для кошек и собак">Для кошек и собак</option>
                        </select>

                        {/* {err.forwho && (
                            <p style={{ color: "red" }}>{err.forwho}</p>
                        )} */}
                    </div>
                    {newProd.type && newProd.forwho && (<>
                        <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                            <label style={{ color: err.kategory ? 'red' : 'black' }} for="pet-select">Выберите Категорию:</label>

                            <select value={newProd.kategory} name="pets" id="pet-select" onChange={(event) => {
                                setNewProd({ ...newProd, kategory: event.target.value });
                            }}>
                                <option value="">--Выберите Категорию--</option>
                                {newProd.type == "Наша продукция" && (<>
                                    <option value="фарш">фарш</option>
                                    <option value="мясные консервы для кошек">мясные консервы для кошек</option>
                                    <option value="мясные консервы для собак">мясные консервы для собак</option>
                                    <option value="сушенная продукция для собак">сушенная продукция для собак</option>
                                    <option value="отдельная мороженная продукция">отдельная мороженная продукция</option>
                                    <option value="рацион">рацион</option>
                                </>
                                )}
                                {newProd.type == "Каталог товаров" && (
                                    <>
                                        <option value="Уход">Уход</option>
                                        <option value="ВетАптека">ВетАптека</option>
                                        <option value="Аксессуары">Аксессуары</option>
                                        <option value="сухой корм">Cухой корм</option>
                                        <option value="Паучи">Паучи</option>
                                    </>
                                )}
                            </select>

                        </div>
                        <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                            <label style={{ color: err.podkategory ? 'red' : 'black' }} for="pet-select">Выберите Подкатегорию:</label>

                            <select value={newProd.podkategory} name="pets" id="pet-select" onChange={(event) => {
                                setNewProd({ ...newProd, podkategory: event.target.value });
                            }}>
                                <option value="">--Выберите Подкатегорию--</option>
                                {newProd.kategory == 'ВетАптека' && (
                                    <>
                                        <option value="От блох и клещей">От блох и клещей</option>
                                        <option value="Витамины">Витамины</option>
                                        <option value="Полость рта">Полость рта</option>
                                        <option value="Другое">Другое</option>
                                    </>
                                )}
                                {newProd.kategory == 'Аксессуары' && (
                                    <>
                                        <option value="Игрушки">Игрушки</option>
                                        <option value="Амуниция">Амуниция</option>
                                        <option value="Лежанки/Домики">Лежанки/Домики</option>
                                        <option value="Другое">Другое</option>
                                    </>
                                )}
                                {newProd.kategory == 'Уход' && (
                                    <>
                                        <option value="Наполнитель">Наполнитель</option>
                                        <option value="Шампунь">Шампунь</option>
                                        <option value="Пеленки">Пеленки</option>
                                        <option value="Другое">Другое</option>
                                    </>
                                )}
                                {(newProd.kategory == 'Паучи' || newProd.kategory == 'сухой корм') && (
                                    <>
                                        <option value="С говядиной">С говядиной</option>
                                        <option value="С курицей">С курицей</option>
                                        <option value="С индейкой">С говядиной</option>
                                        <option value="С теленком">С теленком</option>
                                        <option value="С кроликом">С кроликом</option>
                                        <option value="С лососем">С лососем</option>
                                        <option value="С треской">С треской</option>
                                        <option value="Со свининой">Со свининой</option>
                                        <option value="Другое">Другое</option>
                                    </>
                                )}
                            </select>

                            {/* {err.podkategory && (
                                    <p style={{ color: "red" }}>{err.podkategory}</p>
                                )} */}
                        </div>
                    </>
                    )}




                    <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                        <label style={{ color: err.mnt ? 'red' : 'black' }} for="pet-select">Выберите ед:</label>

                        <select value={newProd.mnt} name="pets" id="pet-select" onChange={(event) => {
                            setNewProd({ ...newProd, mnt: event.target.value });
                        }}>
                            <option value="">--Выберите ед.--</option>

                            <option value="шт">шт</option>
                            <option value="кг">кг</option>
                            <option value="л">л</option>
                            <option value="мл">мл</option>
                        </select>

                        {/* {err.forwho && (
                            <p style={{ color: "red" }}>{err.forwho}</p>
                        )} */}
                    </div>
                    <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                        <label style={{ color: err.brand ? 'red' : 'black' }} for="brand">Введите бренд:</label>
                        <input value={newProd.brand} type="text" id="brand" name="brand" onChange={(event) => {
                            setNewProd({ ...newProd, brand: event.target.value });
                        }} />
                        {/* {err.price && (
                            <p style={{ color: "red" }}>{err.price}</p>
                        )} */}
                    </div>
                    <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                        <label style={{ color: err.price ? 'red' : 'black' }} for="price">Введите розничную цену:</label>
                        <input value={newProd.price} type="text" id="price" name="price" onChange={(event) => {
                            setNewProd({ ...newProd, price: event.target.value });
                        }} />
                        {/* {err.price && (
                            <p style={{ color: "red" }}>{err.price}</p>
                        )} */}
                    </div>
                    {/* <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                        <label style={{ color: err.recomendation ? 'red' : 'black' }} for="text">Введите рекомендации÷ товара:</label>
                        <textarea value={newProd.recomendation} type="text" style={{ height: '200px' }} id="recomendation" name="text" onChange={(event) => {
                            setNewProd({ ...newProd, recomendation: event.target.value });
                        }} />
                     
                    </div> */}
                    <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                        <label style={{ color: err.about ? 'red' : 'black' }} for="text">Введите Описание для нового товара:</label>
                        <textarea value={newProd.about} type="text" style={{ height: '250px', width: '80%' }} id="about" name="text" onChange={(event) => {
                            setNewProd({ ...newProd, about: event.target.value });
                        }} />
                        {/* {err.about && (
                            <p style={{ color: "red" }}>{err.about}</p>
                        )} */}
                    </div>
                    <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                        <label style={{ color: err.shcode ? 'red' : 'black' }} for="shcode">Введите ШК:</label>
                        <input value={newProd.shcode} type="text" id="shcode" name="shcode" onChange={(event) => {
                            setNewProd({ ...newProd, shcode: event.target.value });
                        }} />
                        {/* {err.shcode && (
                            <p style={{ color: "red" }}>{err.shcode}</p>
                        )} */}
                    </div>
                </div>
                <Button text='Создать!' onClick={create} />

            </div>


            {/* <form className={style.form}>
                <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
                {fileURL && <img src={fileURL}></img>}

                <input type="text" id="name" name="name" onChange={(event) => {
                    setNewProd({ ...newProd, name: event.target.value });
                }} />
                {err.name && (
                    <p style={{ color: "red" }}>{err.name}</p>
                )}
                <br></br>
                <label for="pet-select">Выберите Категорию:</label>

                <select name="pets" id="pet-select" onChange={(event) => {
                    setNewProd({ ...newProd, kategory: event.target.value });
                }}>
                    <option value="">--Выберите Категорию--</option>
                    <option value="фарш">фарш</option>
                    <option value="мясные консервы для кошек">мясные консервы для кошек</option>
                    <option value="мясные консервы для собак">мясные консервы для собак</option>
                    <option value="сушенная продукция для собак">сушенная продукция для собак</option>
                    <option value="сушенная продукция для кошек">сушенная продукция для кошек</option>
                    <option value="отдельная мороженная продукция">отдельная мороженная продукция</option>
                    <option value="сухой корм">сухой корм</option>
                    <option value="рацион">рацион</option>
                    <option value="Каталог товаров">Каталог товаров</option>
                    <option value="Амуниция">Амуниция</option>
                </select>

                {err.kategory && (
                    <p style={{ color: "red" }}>{err.kategory}</p>
                )}
                <br></br>
                <label for="pet-select">Для кого:</label>

                <select name="pets" id="pet-select" onChange={(event) => {
                    setNewProd({ ...newProd, forwho: event.target.value });
                }}>
                    <option value="">--Выберите Категорию--</option>
                    <option value="Для кошек">Для кошек</option>
                    <option value="Для собак">Для собак</option>
                    <option value="Для грызунов">Для грызунов</option>
                </select>

                {err.kategory && (
                    <p style={{ color: "red" }}>{err.kategory}</p>
                )}
                <br></br>
                <label for="scladqty">Введите остаток на складе:</label>
                <input type="тгьиук" id="scladqty" name="scladqty" onChange={(event) => {
                    setNewProd({ ...newProd, scladqty: event.target.value });
                }}
                />
                {err.scladqty && (
                    <p style={{ color: "red" }}>{err.scladqty}</p>
                )}
                <br></br>

                <label for="price">Введите розничную цену:</label>
                <input type="text" id="price" name="price" onChange={(event) => {
                    setNewProd({ ...newProd, price: event.target.value });
                }} />
                {err.price && (
                    <p style={{ color: "red" }}>{err.price}</p>
                )}
                <br></br>

                <label for="text">Введите рекомендации÷ товара:</label>
                <textarea type="text" style={{ height: '200px' }} id="recomendation" name="text" onChange={(event) => {
                    setNewProd({ ...newProd, recomendation: event.target.value });
                }} />
                {err.recomendation && (
                    <p style={{ color: "red" }}>{err.recomendation}</p>
                )}
                <br></br>
                <label for="text">Введите Описание для нового товара:</label>
                <textarea type="text" style={{ height: '200px' }} id="about" name="text" onChange={(event) => {
                    setNewProd({ ...newProd, about: event.target.value });
                }} />
                {err.about && (
                    <p style={{ color: "red" }}>{err.about}</p>
                )}
                <br></br>
                <label for="text">Введите ШК:</label>
                <textarea type="text" style={{ height: '200px' }} id="shcode" name="text" onChange={(event) => {
                    setNewProd({ ...newProd, shcode: event.target.value });
                }} />
                {err.shcode && (
                    <p style={{ color: "red" }}>{err.shcode}</p>
                )}
                <br></br>
                <br></br>

                <Button text='Создать!' onClick={create} />
            </form> */}
        </div>
        {loading && (
            <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
        )}
    </>
    )
}
export default NewProdForm