import React from 'react'
// import Button from '../../../../Button/Button'
import style from './NewOrders.module.css'
// import Title from "../../Title";
import { useEffect, useState, useContext } from "react";
import { QtdContext } from "../../../../../context/qtdContext";
import $api from "../../../../../http/index.ts";
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
import { FaEdit, FaRemoveFormat } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import ModalInfo from '../Modal/ModalInfo';
import Button from '../../../../Button/Button';
import { FcInfo, FcCancel } from "react-icons/fc";
import { confirmAlert } from 'react-confirm-alert'; // Import
import './react-confirm-alert.css'; // Import css
import { BACKURL } from '../../../../../conf.js'
import Loading from 'react-fullscreen-loading';

const NewOrders = ({ status }) => {
    const { admin, setInfoModal, modalInfo, setModalInfo, servOrders, setServOrders,shops } = useContext(QtdContext);
    // const { modal, setModal } = useState(false);
    // const servOrders = JSON.parse(localStorage.getItem("servOrders"));
    const [paytaped, setpaytaped] = useState(false);

    const drydey = servOrders.filter((prod) => prod.status == status)
    drydey.sort((a, b) => a.id < b.id ? 1 : -1);

    let NextStatus = ''
    let NextButton = ''
    switch (true) {
        case (status == 'Ожидает оплаты!'):
            NextStatus = 'Оплачен!'
            NextButton = 'Оплачен!'

            break
        case (status == 'Оплачен!'):
            NextStatus = 'Подтвержден!'
            NextButton = 'На Склад!'

            break
        case (status == 'Ожидает подтверждения!'):
            NextStatus = 'Подтвержден!'
            NextButton = 'На Склад!'

            break
        case (status == 'Подтвержден!'):
            NextStatus = 'Ожидает доставки!'
            NextButton = 'Собран!'

            break
        case (status == 'Ожидает доставки!'):
            NextStatus = 'Получен!'
            NextButton = 'Получен!'

            break
    }
    const Nesx = async (id, newStatus) => {
        // console.log(id, newStatus)
        setpaytaped(true)
        const updated = await $api.post(`${BACKURL}/api/utbteam/updateorderstatus/${id}`, { newStatus: newStatus, admin: admin.user })
        // console.log(updated)
        const newPosts = servOrders.map((post) => (
            post.id == updated.data.id
                ? { ...post, status: updated.data.status }
                : post
        ));

        // setSclad(newPosts)
        setServOrders(newPosts)
        // console.log(newProd.data)
        //   localStorage.setItem("servOrders", JSON.stringify(servOrders));
        // console.log(newPosts)
        setpaytaped(false)
        alert('Заказ перемещен!')
    }
    // console.log('servOrders', drydey)

    const ModalInfoB = async (what, type, data, prod, bonus, promo, orderlength) => {
        setInfoModal({ type: type, what: what, data: data, order_id: prod, bonus: bonus, promo: promo, orderlength: orderlength })
        setModalInfo(true)

    }
    const deleteOrder = async (id) => {
        try {
            // console.log('deleteOrder', id)
            const deleteOrderId = await $api.post(`${BACKURL}/api/utbteam/deleteorder/${id}`, { admin: admin.user })
            const newPosts = servOrders.filter((post) => (
                post.id != deleteOrderId.data
            ));

            // setSclad(newPosts)
            setServOrders(newPosts)
            // console.log(newPosts)
            alert('Заказ удален!')

        } catch (e) {
            // console.log(e)
        }
    }
    const submitDeket = async (func, data) => {
        confirmAlert({
            title: 'Подтвердите, чтобы продолжить!',
            message: 'Вы уверены в том, что хотите удалить заказ?',
            buttons: [
                {
                    label: 'Да!',
                    onClick: async () => await func(data)
                },
                {
                    label: 'Нет!',
                    //   onClick: () => alert('Click No')
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
        });
    };
    const submit = async (func, data) => {
        confirmAlert({
            title: 'Подтвердите, чтобы продолжить!',
            message: 'Вы уверены, в том, что хотите обновить статус ордера?',
            buttons: [
                {
                    label: 'Да!',
                    onClick: async () => await func(data.id, data.NextStatus)
                },
                {
                    label: 'Нет!',
                    //   onClick: () => alert('Click No')
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
        });
    };
    return (<>

        <div style={{ overflowX: 'auto' }}>
            <br />
            <table className={style.table}>
                <thead className={style.tableth}>

                    <tr>
                        <td className={style.tabletd}>№</td>
                        <td className={style.tabletd}>ИМЯ</td>
                        <td className={style.tabletd}>ТИП</td>
                        <td className={style.tabletd}>Магазин</td>

                        {status != 'Подтвержден!' && (<>

                            <td className={style.tabletd}>email</td>
                            <td className={style.tabletd}>tel</td>
                        </>
                        )}
                        <td className={style.tabletd}>Доставка</td>
                        <td className={style.tabletd}>Заказ</td>
                        {status != 'Подтвержден!' && (<>
                            <td className={style.tabletd}>Cкидка бонусами</td>
                            <td className={style.tabletd}>Промокод</td>
                            <td className={style.tabletd}>Сумма</td>
                            <td className={style.tabletd}>Итого</td>
                        </>
                        )}

                        <td className={style.tabletd}>Оплата</td>
                        <td className={style.tabletd}>Статус Оплаты</td>
                        <td className={style.tabletd}>Статус</td>
                        {admin.role == 'GOD' && (

                            <td className={style.tabletd}>Удалить</td>
                        )}
                        <td className={style.tabletd}>Время</td>


                        {(status != 'Получен!') && (
                            <td className={style.tabletd}>Далее...</td>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {drydey.map(
                        (prod, index) => {
                            const shop = shops.find(pr=>pr.id==prod.shop_id)

                        return <>
                            <tr>
                                <td className={style.tabletd}>{prod.id}</td>
                                <td className={style.tabletd}>{prod.firstname}</td>
                                <td className={style.tabletd}>{prod.type}</td>
                                <td className={style.tabletd}>{shop?shop.name:''}</td>

                                {status != 'Подтвержден!' && (<>
                                    <td className={style.tabletd}>{prod.email}</td>
                                    <td className={style.tabletd}>{prod.phone}</td>
                                </>
                                )}
                                <td className={style.tabletd}>{prod.deliverytype}
                                    {prod.deliverytype != 'Самовывоз' && (

                                        <FcInfo className={style.icon} onClick={() => { ModalInfoB('Адрес Доставки', prod.type, prod.deliveryadress, prod.id, prod.bonus, prod.promocode, prod.order.length) }} />
                                    )}
                                </td>
                                <td className={style.tabletd}>
                                    <div className={style.cell}><>
                                                                        Cписок<FcInfo onClick={() => { ModalInfoB('Заказ', prod.type, prod.order, prod.id, prod.bonus, prod.promocode, prod.order.length) }} className={style.icon} />

                                    </></div>
                                    
                                    </td>
                                {status != 'Подтвержден!' && (<>
                                    <td className={style.tabletd}>{prod.bonus} RUB</td>
                                    <td className={style.tabletd}>{prod.promocode}%</td>
                                    <td className={style.tabletd}>{prod.amount} RUB</td>
                                    <td className={style.tabletd}>{Number(prod.totalamount)} RUB</td>

                                </>
                                )}
                                <td className={style.tabletd}>{prod.paytype}</td>
                                <td className={style.tabletd}>{prod.paystatus}</td>
                                <td className={style.tabletd}>{prod.status}</td>
                                {admin.role == 'GOD' && (
                                    <td className={style.tabletd}><FcCancel onClick={() => submitDeket(deleteOrder, prod.id)} /></td>
                                )}
                                <td className={style.tabletd}>{prod.createdAt}</td>

                                {(status != 'Получен!') && (

                                    <td className={style.tabletd}>
                                        {paytaped && (
                                            <Button text='Выполняю...' disabled={true} />

                                        )}
                                        {!paytaped && (
                                            <Button text={NextButton} onClick={() => submit(Nesx, { id: prod.id, NextStatus })} />

                                        )}

                                    </td>
                                )}

                            </tr>

                        </>}
                    )}
                </tbody>
            </table>
        </div>
        {modalInfo && (
            <ModalInfo
                status={status}
            />

        )}
         {paytaped&&(
                <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
            )}
    </>
    )
}
export default NewOrders