import React, { useEffect, useContext, useState } from "react";
import { produtos } from "./JsonProdutosDry";
import style from "./Produtos.module.css";
import Modal from "../Modal/Modal";
import { QtdContext } from "../../context/qtdContext";
import { useNavigate } from "react-router-dom";
import Title from '../Title'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ButtonsModal from "../Modal/ButtonsModal/ButtonsModal";
import Button from "../Button/Button";

const RacionsData = ({ amount }) => {
  // const “ = JSON.parse(localStorage.getItem("products"));
  const { shops, setShops, productDots, serverProd, produtosSelecionados, opt, setModalCart, setProdutosSelecionados } = useContext(QtdContext);
  const navigate = useNavigate()
  const shops2 = shops.filter(pr => pr.showed)

  const drydey1 = serverProd.filter((prod) => prod.kategory == "рацион" && prod.showed)
  const drydey = drydey1.sort((a, b) => {
    // Сначала сортируем по условию scladqty > 0
    let maximumA = 0
    let maximumB = 0
    for (let i = 0; i < shops2.length; i++) {
      const scladqtyA = productDots.find(pd =>shops2[i].showed&& pd.prod_sh == a.shcode && pd.prod_id == a.id && shops2[i].id == pd.shop_id)
      const scladqtyB = productDots.find(pd =>shops2[i].showed&& pd.prod_sh == b.shcode && pd.prod_id == b.id && shops2[i].id == pd.shop_id)
      if (scladqtyA) {
        maximumA = maximumA + scladqtyA.scladqty
        // console.log(shops2[i], scladqtyA)
      }
      if (scladqtyB) {
        maximumB = maximumB + scladqtyB.scladqty
        // console.log(shops2[i], scladqtyB)
      }
    }
    if (maximumA > 0 && maximumB === 0) return -1;
    if (maximumA === 0 && maximumB > 0) return 1;

    // Если оба объекта в одной группе, сортируем по name
    return a.name.localeCompare(b.name);
  });
  const GoInfo = (imageId, name) => {
    navigate(`/prodinfo/${imageId}`, {
      state: {
        serverProd: serverProd,

        produtosSelecionados: produtosSelecionados,
        name: name,
        imageId: imageId,
        loca: "/racions"
      }
    });
  };
  const removeItem = async (itemId) => {
    const prodRemove = produtosSelecionados.filter(produtoid => produtoid.id !== itemId)
    setProdutosSelecionados(prodRemove)
    localStorage.setItem("produtosSelecionados", JSON.stringify(prodRemove));

  }
  const changeItensCart = (id) => {
    const produtos = serverProd

    const produtoFoiSelecionado = produtosSelecionados.find((produto) => produto.id == +id)
    const produtoDados = produtos.find((produto) => produto.id == +id);



    const produto = produtoFoiSelecionado ? produtoFoiSelecionado : produtoDados
    const produtosNaoSelecionados = produtosSelecionados.filter((item) => item.id !== +id);
    const novosProdutos = [...produtosNaoSelecionados, produto].sort((a, b) => a.id - b.id);
    // console.log(novosProdutos, produto)
    setProdutosSelecionados(novosProdutos);
    localStorage.setItem("produtosSelecionados", JSON.stringify(novosProdutos));

    // console.log(produtosNaoSelecionados, produtosSelecionados)
    // setModal(false);
    // console.log('novosProdutos')
  }
  // let tt = photo[0]
  // if(!photo){
  //   tt = ''
  // }


  // console.log('====================', produtoFoiSelecionado, produtos)
  // console.log('prodinfi', imageId, produtosSelecionados, produtoDados)



  return (
    <>  <br />
      <div className={style.borders}>
        <br />
        <p className={style.p}>
          Также представляем вашему вниманию линейку натуральных свежемороженных рационов  торговой марки КотоPes — сбалансированное и здоровое питание для ежедневного кормления ваших домашних питомцев. 8 рационов для собак всех пород и 2 рациона для кошек! Изготовлено из 100% натуральных продуктов, используемых в питании человека (свежие ингредиенты категории Human Grade – человеческий стандарт качества). Структура и компонентный состав рационов удовлетворяют потребность в необходимых питательных веществах и энергии.      </p>
        <br />
        <p className={style.p}>
          Важно: рационы не содержат консерванты, усилители вкуса, красители, ароматизаторы и зерновые культуры. ТОЛЬКО НАТУРАЛЬНЫЕ МЯСНЫЕ ИНГРЕДИЕНТЫ И ОВОЩИ!      </p>
        <br />
      </div>
      <Title text="Рационы" />
      <br />
      <div className={style.produtos_container}>
        {!opt.auth && drydey.map(
          ({ photo, name, price, id, scladqty, forwho, shcode, mnt }, index) => {
            let tt = ''
            if (photo[0]) {
              tt = photo[0]
            }
            const cart = produtosSelecionados.find((produto) => produto.id == +id);
            let cartb = false
            if (cart) {
              cartb = true
            }

            let maximum2 = 0
            for (let i = 0; i < shops2.length; i++) {
              const scladqty = productDots.find(pd => shops2[i].showed && pd.prod_sh == shcode && pd.prod_id == id && shops2[i].id == pd.shop_id)
              if (scladqty) {
                maximum2 = maximum2 + scladqty.scladqty
                // console.log(shops2[i], scladqty)
              }
            }
            // console.log(maximum2)


            return index <= amount && (
              <div key={id} id={id} className={style.cards_produtos} style={{ opacity: maximum2 == 0 ? "0.5" : "1" }}>
                {/* <img id={id} className={style.img} src={tt} /> */}
                <div onClick={() => GoInfo(id, name)} className={style.imgcont} style={{ height: '250px' }}>
                  <LazyLoadImage
                    id={id}
                    className={style.img}
                    src={tt}
                    alt="Example Image"
                    height="100%"
                    effect="blur"
                  // src="http://localhost:3000/static/images/example.jpg" // Замените на ваш путь к изображению
                  // width="600px"
                  />
                </div>

                <p onClick={() => GoInfo(id, name)} id={id}>{name}</p>
                <p className={style.p}>Наличие:     </p>
                {shops2.length > 0 && (shops2.filter(pr => pr.showed).map(pr => {
                  const scladqty = productDots.find(pd => pd.prod_sh == shcode && pd.prod_id == id && pr.id == pd.shop_id)
                  // console.log(productDots, scladqty)
                  return <p style={{fontWeight: 'normal'}}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{mnt}</b></p>






                }))}
                {/* <p id={id} className={style.price}><b>{price}RUB</b></p> */}
                <div className={style.vbadgecont} onClick={() => GoInfo(id, name)}>
                  {forwho == 'Для собак' && (
                    <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      🐶<span></span>
                    </div>
                  )}
                  {forwho == 'Для кошек' && (
                    <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      🐱<span></span>
                    </div>
                  )}
                  {name == 'Мясные консервы «говядина с потрошками» 525г' && (
                    <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#04b31b', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      ХИТ
                      <span></span>
                    </div>
                  )}
                  {Number(maximum2) > 0 &&
                    <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      {price}RUB
                      <span></span>
                    </div>}
                  {Number(maximum2) <= 0 &&
                    <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      Распродано!
                      <span></span>
                    </div>}

                  <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#20b6e8', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    Натуральный
                    <span></span>
                  </div>


                </div>
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>
                  {!cartb && Number(maximum2) > 0 && (<>


                    <ButtonsModal id={id} max={maximum2} />
                    <Button style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить В Корзину" onClick={() => changeItensCart(id)} />
                  </>
                  )}
                  {cartb && Number(maximum2) > 0 && (<>
                    <Button width={'100px'} text="В Корзину" style={{ 'width': '100px' }} onClick={() => setModalCart(true)} />
                    <Button width={'100px'} text="Удалить из Корзины " style={{ 'width': '130px' }} onClick={() => removeItem(id)} />
                  </>
                  )}
                </div>

              </div>
            )
          }
        )}

        <br />
      </div>

    </>
  );
};

export default RacionsData;
